<template>
  <div>
    <div class="overlay-alert">
      <v-alert
          dismissible
          type="success"
          v-model="alertSuccess"
          transition="scale-transition">
        success! - {{ textSuccessAlert }}
      </v-alert>
      <v-alert
          dismissible
          type="error"
          v-model="alertError"
          transition="scale-transition">
        Error! - {{ textErrorAlert }}
      </v-alert>
    </div>
    <div v-if="loading" class="progress-barr">
      <div class="progress">
        <v-progress-circular
            :size="100"
            :width="10"
            color="blue"
            indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-layout justify-center>
      <v-flex xs12>
        <v-data-table
            :height="heightTable()"
            fixed-header
            :headers="scheduleGrid.headers"
            :items="scheduleGrid.items"
            :items-per-page="290"
            :hide-default-footer="true"
            :hide-default-header="true"
            :class="tableClass"
            :single-expand="true"
            v-shortkey="{ 'copyEn':['ctrl', 'c'], 'copyRu':['ctrl', 'с'], 'insetEn': ['ctrl', 'v'], 'insetRu': ['ctrl', 'м']}"
            @shortkey.native="itemAction"
        >

          <template v-slot:header="{ props }">
            <table-header
                :props="props"
                @updateJournal="getJournal()"
            ></table-header>
          </template>


          <template v-slot:item="item">
            <table-item
                :item="item"
                :headers="scheduleGrid.headers"
                :yearOfStud="yearOfStud"
                :scheduleType="scheduleType"
                @showDialogEditLessons="showDialogEditLessons"
                @showDialogDeleteLessons="showDialogDeleteLessons"
                @refreshLessons="refreshLessons"
            ></table-item>
          </template>

        </v-data-table>
      </v-flex>
    </v-layout>

    <lessons-edit
        ref="lessonEdit"
        :show="dialog_lesson"
        :title="title_dialog"
        :lessons="newLessons"
        :yearOfStud="yearOfStud"
        :halfYear="halfYear"
        @closeEditLessonDialog="closeEditLessonDialog"
        @saveLessonSuccess="saveLessonSuccess"
        @showAlertError="showAlertError"
        @deleteLessonSuccess="deleteLessonSuccess"
        @refreshLessons="refreshLessons"
    ></lessons-edit>

    <lessons-delete
        ref="lessonDelete"
        :show="dialog_delete_lesson"
        :title="title_dialog"
        :lessons="newLessons"
        :yearOfStud="yearOfStud"
        :halfYear="halfYear"
        @closeEditLessonDialog="closeDeleteLessonDialog"
        @showAlertError="showAlertError"
        @deleteLessonSuccess="deleteLessonSuccess"
        @refreshLessons="refreshLessons"
    ></lessons-delete>

    <SchedulePrint
        :show="showPrintDialog"
        :headers="getHeaders()"
        :scheduleType="selectScheduleType"
        :year="yearOfStud"
        :halfYear="halfYear"
        :course="course"
        :parity="parity"
        @closePrintDialog="closePrintDialog"
    ></SchedulePrint>



  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

import TableHeader from '@/components/schedule/TableHeader';
import TableItem from '@/components/schedule/TableItem';
import LessonsEdit from '@/components/schedule/dialog/LessonsEdit';
import LessonsDelete from '@/components/schedule/dialog/LessonsDelete';
import SchedulePrint from "@/components/schedule/dialog/SchedulePrint";


export default {
  name: "ScheduleEvenOdd",
  components: {
    TableHeader,
    TableItem,
    LessonsEdit,
    LessonsDelete,
    SchedulePrint,
  },
  props: {
    showPrintDialog: Boolean,
    scheduleType: String,
    selectScheduleType: String,
    parity: Number,
    course: Number,
    yearOfStud: Object,
    halfYear: Object,
    typesEducation: String,
    faculty: Array,
    direction: String,
    month: Array,
    year: String,
    isCopyAudience: Boolean,
    ignoreAllDoubleForCopy: Boolean,
    daysOfWeek: Array,
    formOfTraining: Array,
  },
  data() {
    return {
      dialog_lesson: false,
      dialog_delete_lesson: false,
      title_dialog: "",
      scheduleGrid: {
        headers: [],
        items: [],
      },
      newLessons: {},
      ccScheduledLesson: {
        audiences: [],
        date: [],
        id: null,
        isCheckDuplicateAudience: true,
        isCheckDuplicateTeacher: true,
        lessonNumbers: {},
        lessonTypeDays: {},
        numberLessons: 0,
        numberLessonsAdded: 0,
        numberLessonsPlan: 0,
        schedule: {},
        scheduleType: {},
        workload: {},
        workloadStreamLessons: null
      },
      ccLessons: {
        id: null,
        // workload: {
        //   workPlan:{
        //     hours: null,
        //     discipline: {
        //       id: null
        //     },
        //     lessonTypes: {
        //       id: null,
        //     },
        //     halfYear: {
        //       id: null,
        //     },
        //     yearOfStudy: {
        //       id: null,
        //     },
        //     group: {
        //       id: null,
        //     },
        //   },
        //   id: null,
        //   teachers: [],
        //   mainTeachers: null,
        //   replacementTeachers: [],
        // },
        // workloadStreamLessons:[],
        // teacherLinkToAudience: [],
        audiences: [],
        isCheckDuplicateAudience: false,
        isCheckDuplicateTeacher: false,
      },

      loading: false,
      alertSuccess: false,
      alertError: false,
      textSuccessAlert: "",
      textErrorAlert: "",


      curriculums: [],
    }
  },
  computed: {
    tableClass(){
      if (this.$store.getters.settings_color_themes){
        return "elevation-2 schedule-data-table"
      }
      return "elevation-2"
    },
    selectItemId: {
      get() {
        return this.$store.getters.selectScheduleTableItemId;
      },
      set(value) {
        this.$store.commit('updateSelectScheduleTableItemId', value)
      }
    },
    selectItem: {
      get() {
        return this.$store.getters.selectScheduleTableItem;
      },
      set(value) {
        this.$store.commit('updateSelectScheduleTableItem', value)
      }
    },
    copyItem: {
      get() {
        return this.$store.getters.copyScheduleTableItem;
      },
      set(value) {
        this.$store.commit('updateCopyScheduleTableItem', value)
      }
    },
  },
  watch: {
    selectScheduleType() {
      if (this.scheduleType === this.selectScheduleType) {
        this.getJournal()
      }
    },
  },

  methods: {

    closePrintDialog() {
      this.$emit("closePrintDialog")
    },
    getHeaders() {
      return this.scheduleGrid.headers
    },
    deleteLessonSuccess() {
      this.responseDelete()
      this.dialog_lesson = false
      this.dialog_delete_lesson = false
    },
    showDialogEditLessons: function (item) {
      // console.log(item)
      this.newLessons = item
      // this.newLessons.workloadStreamLessons = []
      this.title_dialog = "Добавить пару"
      this.dialog_lesson = true
    },
    showDialogDeleteLessons: function (item) {
      // console.log(item)
      this.newLessons = item
      // this.newLessons.workloadStreamLessons = []
      this.title_dialog = "Добавить пару"
      this.dialog_delete_lesson = true
    },
    async closeEditLessonDialog() {
      this.dialog_lesson = false
      // if (this.newLessons.id != null) {
      //   await this.getLesson(this.newLessons.id)
      // } else {
      //   await this.responseDelete()
      // }
    },
    async closeDeleteLessonDialog() {
      this.dialog_delete_lesson = false
      // if (this.newLessons.id != null) {
      //   await this.getLesson(this.newLessons.id)
      // } else {
      //   await this.responseDelete()
      // }
    },
    refreshLessons() {
      this.getJournal(true)
    },

    showAlertSuccess(message) {
      this.alertSuccess = true
      this.textSuccessAlert = message
      setTimeout(this.hideAlertSuccess, 2000);
    },
    hideAlertSuccess() {
      this.alertSuccess = false
    },
    showAlertError(message) {
      this.alertError = true
      this.textErrorAlert = message
      setTimeout(this.hideAlertError, 2000);
    },
    async saveLessonSuccess(lesson) {
      await this.responseLessons(lesson)
      this.dialog_lesson = false
    },
    hideAlertError() {
      this.alertError = false
    },
    itemAction(event) {
      switch (event.srcKey) {
        case 'copyEn':
          this.copedItem()
          break
        case 'copyRu':
          this.copedItem()
          break
        case 'insetEn':
          this.insertItem()
          break
        case 'insetRu':
          this.insertItem()
          break
      }
    },
    copedItem() {
      // console.log(this.selectItem)
      if (this.selectItem.scheduledLessons.length == 1 &&
          this.selectItem.scheduledLessons[0].id != null &&
          this.selectItem.scheduledLessons[0].id != undefined) {
        this.copyItem = {...this.selectItem}
        this.showAlertSuccess("item copied")
      } else {
        this.showAlertError("item empty")
      }

    },

    getCurriculum: async function (item) {
      this.loading = true
      // console.log(item)
      await HTTP.get(Settings.API.URL_GROUPS_BRSO_CURRICULUM_BY_GROUPID_HALFYEAR(item.groups.id, this.yearOfStud.id, this.halfYear.id))
          .then((response) => {
            this.curriculums = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },

    async insertItem() {
      if (this.copyItem.scheduledLessons.length == 1 &&
          this.copyItem.scheduledLessons[0].id != null &&
          this.copyItem.scheduledLessons[0].id != undefined) {
        this.newLessons = this.selectItem
        this.curriculums = []
        await this.getCurriculum(this.newLessons)
        let copyItem = this.copyItem
        let newLessons = this.newLessons
        let isSearch = false
        let isCopyAudience = this.isCopyAudience
        let newShLs = {...this.ccScheduledLesson}
        this.curriculums.forEach(function (item) {
          if (
              item.workPlan.discipline.codeOneC == copyItem.scheduledLessons[0].workload.workPlan.discipline.codeOneC &&
              item.workPlan.lessonTypes.codeOneC == copyItem.scheduledLessons[0].workload.workPlan.lessonTypes.codeOneC
          ) {
            newShLs.date = newLessons.date
            newShLs.lessonNumbers = newLessons.lessonNumbers
            newShLs.lessonTypeDays = newLessons.lessonTypeDays
            newShLs.schedule = newLessons.schedule
            newShLs.scheduleType = newLessons.scheduleType
            newShLs.scheduleType = newLessons.scheduleType
            newShLs.workload = item

            // console.log(copyItem)
            // console.log(newLessons)
            // console.log(item)
            // newLessons.scheduledLessons[0].workload = item
            if (isCopyAudience) {
              newShLs.audiences = copyItem.scheduledLessons[0].audiences
            }
            newLessons.scheduledLessons.push(newShLs)
            isSearch = true
          }
        })

        if (isSearch) {
          if (this.ignoreAllDoubleForCopy) {
            await this.$refs.lessonEdit.saveLesson(false, false)
          } else {
            await this.$refs.lessonEdit.saveLesson(true, true)
          }

        } else {
          this.showAlertError("no discipline plan found")
          // newLessons.workload = {
          //   replacementTeachers: [],
          // }
        }
      } else {
        this.showAlertError("item not copied")
      }

    },
    responseDelete: async function () {
      if (this.newLessons.scheduledLessons.length > 0) {
        await this.getJournal(true)
      }
      // this.newLessons.id = null
      // this.newLessons.workload.replacementTeachers = []
      // this.newLessons.workload.mainTeachers = null
      // this.newLessons.workload.id = null
      // this.newLessons.workload.replacementTeachers = []
      // this.newLessons.workload.teachers = []
      // this.newLessons.workload.workPlan.discipline = null
      // this.newLessons.audiences = []
      // this.newLessons.isCheckDuplicateAudience = false
      // this.newLessons.isCheckDuplicateTeacher = false
      //
      // this.newLessons.teacherLinkToAudience = []
    },

    async updateNumberLessons(less) {
      if (less.scheduledLessons != null && less.scheduledLessons.length > 0) {
        for (let z = 0; z < less.scheduledLessons.length; z++) {

          for (let i = 0; i < this.scheduleGrid.items.length; i++) {
            for (const [, value] of Object.entries(this.scheduleGrid.items[i])) {
              if (value instanceof Object &&
                  value.scheduledLessons != null && value.scheduledLessons.length > 0) {
                // less.scheduledLessons[z].workload.id === value.workload.id
                // console.log(value)
                for (let k = 0; k < value.scheduledLessons.length; k++) {
                  if (less.scheduledLessons[z].workload.id === value.scheduledLessons[k].workload.id) {
                    value.scheduledLessons[k].numberLessonsPlan = less.scheduledLessons[z].numberLessonsPlan
                    value.scheduledLessons[k].numberLessons = less.scheduledLessons[z].numberLessons
                    value.scheduledLessons[k].numberLessonsAdded = less.scheduledLessons[z].numberLessonsAdded
                  }
                }
              }
            }
          }
        }
      }

    },

    // async updateNumberLessons(less) {
    //   for (let i = 0; i < this.scheduleGrid.items.length; i++) {
    //     for (const [, value] of Object.entries(this.scheduleGrid.items[i])) {
    //       if (value instanceof Object &&
    //           less.workload.id === value.workload.id) {
    //         value.numberLessonsPlan = less.numberLessonsPlan
    //         value.numberLessons = less.numberLessons
    //         value.numberLessonsAdded = less.numberLessonsAdded
    //       }
    //     }
    //   }
    // },
    responseLessons: function (newLessons) {

      // this.newLessons.id = newLessons.id
      // this.newLessons.date = newLessons.date
      // this.newLessons.schedule = newLessons.schedule
      // this.newLessons.workload = newLessons.workload
      // this.newLessons.lessonNumbers = newLessons.lessonNumbers
      // this.newLessons.lessonTypeDays = newLessons.lessonTypeDays
      // this.newLessons.audiences = newLessons.audiences
      // this.newLessons.isCheckDuplicateAudience = newLessons.isCheckDuplicateAudience
      // this.newLessons.isCheckDuplicateTeacher = newLessons.isCheckDuplicateTeacher
      // this.newLessons.numberLessonsPlan = newLessons.numberLessonsPlan
      // this.newLessons.numberLessons = newLessons.numberLessons
      // this.newLessons.numberLessonsAdded = newLessons.numberLessonsAdded
      //
      // this.newLessons.teacherLinkToAudience = newLessons.teacherLinkToAudience

      this.newLessons.date = newLessons.date
      this.newLessons.groups = newLessons.groups
      this.newLessons.lessonNumbers = newLessons.lessonNumbers
      this.newLessons.lessonTypeDays = newLessons.lessonTypeDays
      this.newLessons.schedule = newLessons.schedule
      this.newLessons.scheduleType = newLessons.scheduleType
      this.newLessons.scheduledLessons = newLessons.scheduledLessons

      this.updateNumberLessons(newLessons)

    },
    getLesson: async function (id) {
      this.loading = true
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_LESSONS + id
      ).then((response) => {
        this.responseLessons(response.data)
      }).catch(() => {
      }).finally(() => (this.loading = false));
    },


    heightTable: function () {
      return (window.screen.height - 300) + "px"
    },
    getJournal: function (isUpdate = false) {
      if (this.scheduleType === this.selectScheduleType) {
        if (!isUpdate) {
          this.scheduleGrid = {}
        }
        this.loading = true
        const data = {
          daysOfWeek: this.daysOfWeek,
          months: this.month,
          faculty: this.faculty,
          type: this.formOfTraining
        }
        console.log(data)
        HTTP.post(Settings.API.URL_DEFAULT + Settings.API.URL_SCHEDULE + "edit-schedule-table?course=" +
            this.course + "&halfYear=" + this.halfYear.id + "&parity=" +
            this.parity + "&scheduleType=" + this.scheduleType + "&yearOfStudy=" +
            this.yearOfStud.name + "&typesEducation=" + this.typesEducation
            + "&direction=" + this.direction
            + "&year=" + (this.year != "" ? this.year : 0), // "&month=" + this.month +
            data
        )
            .then((response) => {
              this.scheduleGrid = response.data
              // console.log(this.scheduleGrid)
            }).catch(() => {

          this.showAlertError("Error connect server")
          this.scheduleGrid = {}

        }).finally(() => (this.loading = false));
      }
    },
  },
  async created() {
    // this.newLessons = {...this.ccLessons};
    this.$parent.$on('getJournal', this.getJournal);
  },
}
</script>

<style lang="sass">

@import '~vuetify/src/styles/main.sass'
table
  tbody
    tr
      td:nth-child(1)
        position: sticky !important
        position: -webkit-sticky !important
        left: 0
        padding: 0 !important
        opacity: 1
        z-index: 2


.name-td
  /*margin-left: -15px;*/
  /*margin-right: 0;*/
  display: flex
  justify-content: left
  align-items: center
  padding-left: 10px
  width: 100%
  height: 100%
  background-color: white !important

.name-td-dark
  display: flex
  justify-content: left
  align-items: center
  padding-left: 10px
  width: 100%
  height: 100%
  background-color: #1e1e1e !important


.progress-barr
  display: block
  position: fixed
  //right: 6px
  height: 100%
  width: 100%
  background: rgb(0, 0, 0, 0.2)
  z-index: 999
  text-align: center


.progress
  margin-top: 15%

.schedule-data-table
  color: #a9a9a9 !important

</style>
