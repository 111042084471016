<template>
  <tr>
    <td
      class="td-border-right v-data-table__divider td-schedule-edit"
      :style="getBorder(item.item[header.value])"
      v-for="header in headers"
      :key="header.text"
      @click="selectedItem(item.item[header.value])"
      @dblclick="showDialogEditLessons(item.item[header.value])"
    >
      <div v-if="header.value === 'name'" :class="getClassTd()">
        {{ item.item[header.value] }}
      </div>
      <div v-else>
        <v-spacer></v-spacer>

        <div v-if="item.item[header.value].scheduledLessons.length > 0">
          <div
            v-for="less in item.item[header.value].scheduledLessons"
            :key="less.id"
          >
            <div v-if="less.id">
              <v-row>
                <v-col>
                  <v-btn
                    width="10px"
                    height="10px"
                    class="mt-1"
                    v-if="less.id"
                    :to="getLinkCalendar(less)"
                    target="_blank"
                    icon
                  >
                    <v-icon color="#a9a9a9">date_range</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  {{ less.numberLessonsPlan * 2 }}/{{
                    less.numberLessonsAdded * 2
                  }}/{{ less.numberLessons * 2 }}
                </v-col>
                <v-col>
                  <v-btn
                    width="10px"
                    height="10px"
                    class="mt-1"
                    v-if="less.id"
                    @click="
                      updateProjector(
                        readWorkPlanId(item.item[header.value]),
                        !readProjector(item.item[header.value])
                      )
                    "
                    icon
                  >
                    <img
                      v-if="readProjector(item.item[header.value])"
                      width="25"
                      src="@/assets/projector-active.svg"
                    />
                    <img v-else width="25" src="@/assets/projector.svg" />
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <template>
          <div>
            <div v-if="item.item[header.value].scheduledLessons.length > 0">
              <div class="ma-0">
                <span
                  ><b> {{ readDiscipline(item.item[header.value]) }}</b>
                </span>
              </div>
              <div v-if="item.item[header.value].id !== null" class="ma-0">
                <em>({{ readLessonType(item.item[header.value]) }}) </em>
              </div>

              <div class="ma-0">
                <span
                  v-for="(teacher, i) in readTeachers(item.item[header.value])"
                  :key="i"
                >
                  <b>{{ teacher }}</b
                  >,
                </span>
              </div>

              <div
                v-if="item.item[header.value].scheduledLessons.length > 0"
                class="ma-0"
              >
                <em>Аудитория - </em>
                <span
                  v-for="item in readAudience(item.item[header.value])"
                  v-bind:key="item.id"
                >
                  <b>{{ item.name }}</b
                  >,
                </span>
              </div>
              <div
                v-if="
                  item.item[header.value].dates != null &&
                  item.item[header.value].dates.length > 0
                "
              >
                <template>
                  <div class="text-center">
                    <v-dialog width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red lighten-2"
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>info</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Занятые даты.
                        </v-card-title>

                        <v-card-text>
                          <div
                            v-for="(val, i) in item.item[header.value].dates"
                            :key="i"
                          >
                            {{ val }}
                          </div>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </td>
  </tr>
</template>

<script>
import { HTTP } from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "TableItem",
  components: {},
  props: {
    item: Object,
    headers: Array,
    yearOfStud: Object,
    scheduleType: String,
  },
  data() {
    return {};
  },
  computed: {
    settings_color_themes: {
      get() {
        return this.$store.getters.settings_color_themes;
      },
      set(value) {
        this.$store.commit("update_settings_color_themes", value);
        this.$vuetify.theme.dark = this.$store.getters.settings_color_themes;
      },
    },
    selectItemId: {
      get() {
        return this.$store.getters.selectScheduleTableItemId;
      },
      set(value) {
        this.$store.commit("updateSelectScheduleTableItemId", value);
      },
    },
    selectItem: {
      get() {
        return this.$store.getters.selectScheduleTableItem;
      },
      set(value) {
        this.$store.commit("updateSelectScheduleTableItem", value);
      },
    },
    copyItem: {
      get() {
        return this.$store.getters.copyScheduleTableItem;
      },
      set(value) {
        this.$store.commit("updateCopyScheduleTableItem", value);
      },
    },
  },
  methods: {
    getClassTd() {
      if (this.settings_color_themes) {
        return "name-td-dark";
      }
      return "name-td";
    },
    updateProjector(id, f) {
      HTTP.get(Settings.API.URL_DEFAULT + "/workpaln/projector?id=" + id + "&status=" + f)
        .then(() => {
               this.$emit("refreshLessons")

        })
        .catch(() => {})
        .finally(() => {});
    },
    showDialogEditLessons: function (item) {
      if (
        item instanceof Object &&
        item.scheduleType.type === this.scheduleType
      ) {
        this.$emit("showDialogEditLessons", item);
      } else {
        this.$emit("showDialogDeleteLessons", item);
        console.log(item);
      }
    },
    getLinkCalendar(item) {
      return (
        "/lessons-calendar?plan=" +
        item.workload.id +
        "&year=" +
        this.yearOfStud.yearStart
      );
    },
    getBorder: function (item) {
      let style = "";

      if (
        item instanceof Object &&
        item.scheduleType.type !== this.scheduleType
      ) {
        style += " background-color: #CFD8DC; ";
      }

      if (
        item instanceof Object &&
        (item.lessonNumbers.number == 7 || item.lessonNumbers.number == 9) &&
        item instanceof Object &&
        this.isSelectedItem(item)
      ) {
        style +=
          "border-bottom: 3px solid; border-top: 2px dashed; border-left: 2px dashed; border-right: 2px dashed;";
      } else if (
        item instanceof Object &&
        (item.lessonNumbers.number == 7 || item.lessonNumbers.number == 9)
      ) {
        style += "border-bottom: 3px solid";
      } else if (item instanceof Object && this.isSelectedItem(item)) {
        style += "border: 2px dashed";
      }
      return style;
    },
    selectedItem(item) {
      // console.log(item)
      this.selectItemId =
        item.schedule.id +
        "-" +
        item.lessonNumbers.id +
        "-" +
        (item.scheduleType != undefined &&
        item.scheduleType != null &&
        item.scheduleType.type == "EVEN_ODD"
          ? item.lessonTypeDays.id
          : item.date);
      this.selectItem = item;
    },
    isSelectedItem(item) {
      if (
        this.selectItemId ===
        item.schedule.id +
          "-" +
          item.lessonNumbers.id +
          "-" +
          (item.scheduleType != undefined &&
          item.scheduleType != null &&
          item.scheduleType.type == "EVEN_ODD"
            ? item.lessonTypeDays.id
            : item.date)
      ) {
        return true;
      }
      return false;
    },

    readWorkPlanId: function (item) {
      if (
        item.scheduledLessons != null &&
        item.scheduledLessons.length > 0 &&
        item.scheduledLessons[0].workload != null &&
        item.scheduledLessons[0].workload.workPlan != null
      ) {
        return item.scheduledLessons[0].workload.workPlan.id;
      }
      return "";
    },

    readProjector: function (item) {
      if (
        item.scheduledLessons != null &&
        item.scheduledLessons.length > 0 &&
        item.scheduledLessons[0].workload != null &&
        item.scheduledLessons[0].workload.workPlan != null
      ) {
        return item.scheduledLessons[0].workload.workPlan.projector;
      }
      return "";
    },

    readDiscipline: function (item) {
      if (
        item.scheduledLessons != null &&
        item.scheduledLessons.length > 0 &&
        item.scheduledLessons[0].workload != null &&
        item.scheduledLessons[0].workload.workPlan != null &&
        item.scheduledLessons[0].workload.workPlan.discipline != null
      ) {
        return item.scheduledLessons[0].workload.workPlan.discipline.name;
      }
      return "";
    },

    readLessonType: function (item) {
      if (
        item.scheduledLessons != null &&
        item.scheduledLessons.length > 0 &&
        item.scheduledLessons[0].workload != null &&
        item.scheduledLessons[0].workload.workPlan != null
      ) {
        return item.scheduledLessons[0].workload.workPlan.lessonTypes.name;
      }
      return "";
    },

    readTeachers: function (item) {
      let teachers = [];
      // console.log(item)
      if (item.scheduledLessons != null && item.scheduledLessons.length > 0) {
        for (let i = 0; i < item.scheduledLessons.length; i++) {
          let th = "";
          if (item.scheduledLessons[i].workload.teacher != null) {
            th = item.scheduledLessons[i].workload.teacher.fio;
          }
          if (
            item.scheduledLessons[i].workload.replacementTeachers != null &&
            item.scheduledLessons[i].workload.replacementTeachers.length > 0
          ) {
            th += " (";
            for (
              let k = 0;
              k < item.scheduledLessons[i].workload.replacementTeachers.length;
              k++
            ) {
              th +=
                item.scheduledLessons[i].workload.replacementTeachers[k].fio +
                ", ";
            }
            th += ")";
          }
          teachers.push(th);
          // if (item.scheduledLessons[i].workload.replacementTeachers != null && item.scheduledLessons[i].workload.replacementTeachers.length > 0){
          //   teachers.concat(item.scheduledLessons[i].workload.replacementTeachers)
          // }
        }
      }
      return teachers;
    },

    readAudience(item) {
      let audience = [];
      if (item.scheduledLessons != null && item.scheduledLessons.length > 0) {
        for (let i = 0; i < item.scheduledLessons.length; i++) {
          if (
            item.scheduledLessons[i].audiences != null &&
            item.scheduledLessons[i].audiences.length > 0
          ) {
            for (
              let a = 0;
              a < item.scheduledLessons[i].audiences.length;
              a++
            ) {
              // console.log(item.scheduledLessons[i].workload)
              if (
                item.scheduledLessons[i].audiences[a].name === "MS Teams" &&
                item.scheduledLessons[i].workload.invitationCode != null &&
                item.scheduledLessons[i].workload.invitationCode != undefined &&
                item.scheduledLessons[i].workload.invitationCode != ""
              ) {
                item.scheduledLessons[i].audiences[a].name =
                  item.scheduledLessons[i].audiences[a].name +
                  "( Код команды: " +
                  item.scheduledLessons[i].workload.invitationCode +
                  " )";
              }
            }
            audience = audience.concat(item.scheduledLessons[i].audiences);
          }
        }
      }

      return audience;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.td-schedule-edit {
  vertical-align: top !important;
}
</style>
