var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',_vm._l((_vm.headers),function(header){return _c('td',{key:header.text,staticClass:"td-border-right v-data-table__divider td-schedule-edit",style:(_vm.getBorder(_vm.item.item[header.value])),on:{"click":function($event){return _vm.selectedItem(_vm.item.item[header.value])},"dblclick":function($event){return _vm.showDialogEditLessons(_vm.item.item[header.value])}}},[(header.value === 'name')?_c('div',{class:_vm.getClassTd()},[_vm._v(" "+_vm._s(_vm.item.item[header.value])+" ")]):_c('div',[_c('v-spacer'),(_vm.item.item[header.value].scheduledLessons.length > 0)?_c('div',_vm._l((_vm.item.item[header.value].scheduledLessons),function(less){return _c('div',{key:less.id},[(less.id)?_c('div',[_c('v-row',[_c('v-col',[(less.id)?_c('v-btn',{staticClass:"mt-1",attrs:{"width":"10px","height":"10px","to":_vm.getLinkCalendar(less),"target":"_blank","icon":""}},[_c('v-icon',{attrs:{"color":"#a9a9a9"}},[_vm._v("date_range")])],1):_vm._e()],1),_c('v-col',[_vm._v(" "+_vm._s(less.numberLessonsPlan * 2)+"/"+_vm._s(less.numberLessonsAdded * 2)+"/"+_vm._s(less.numberLessons * 2)+" ")]),_c('v-col',[(less.id)?_c('v-btn',{staticClass:"mt-1",attrs:{"width":"10px","height":"10px","icon":""},on:{"click":function($event){_vm.updateProjector(
                      _vm.readWorkPlanId(_vm.item.item[header.value]),
                      !_vm.readProjector(_vm.item.item[header.value])
                    )}}},[(_vm.readProjector(_vm.item.item[header.value]))?_c('img',{attrs:{"width":"25","src":require("@/assets/projector-active.svg")}}):_c('img',{attrs:{"width":"25","src":require("@/assets/projector.svg")}})]):_vm._e()],1)],1)],1):_vm._e()])}),0):_vm._e(),[_c('div',[(_vm.item.item[header.value].scheduledLessons.length > 0)?_c('div',[_c('div',{staticClass:"ma-0"},[_c('span',[_c('b',[_vm._v(" "+_vm._s(_vm.readDiscipline(_vm.item.item[header.value])))])])]),(_vm.item.item[header.value].id !== null)?_c('div',{staticClass:"ma-0"},[_c('em',[_vm._v("("+_vm._s(_vm.readLessonType(_vm.item.item[header.value]))+") ")])]):_vm._e(),_c('div',{staticClass:"ma-0"},_vm._l((_vm.readTeachers(_vm.item.item[header.value])),function(teacher,i){return _c('span',{key:i},[_c('b',[_vm._v(_vm._s(teacher))]),_vm._v(", ")])}),0),(_vm.item.item[header.value].scheduledLessons.length > 0)?_c('div',{staticClass:"ma-0"},[_c('em',[_vm._v("Аудитория - ")]),_vm._l((_vm.readAudience(_vm.item.item[header.value])),function(item){return _c('span',{key:item.id},[_c('b',[_vm._v(_vm._s(item.name))]),_vm._v(", ")])})],2):_vm._e(),(
                _vm.item.item[header.value].dates != null &&
                _vm.item.item[header.value].dates.length > 0
              )?_c('div',[[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red lighten-2","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Занятые даты. ")]),_c('v-card-text',_vm._l((_vm.item.item[header.value].dates),function(val,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(val)+" ")])}),0),_c('v-divider'),_c('v-card-actions',[_c('v-spacer')],1)],1)],1)],1)]],2):_vm._e()]):_vm._e()])]],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }